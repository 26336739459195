import { Helmet } from "react-helmet";

function Tab() {
  return (
    <Helmet>
      <title>Liam Maguire</title>
    </Helmet>
  );
}

export default Tab;
