import { useEffect, useState } from "react";

function SvgTrace(props) {
  const [isHovered, setIsHovered] = useState(false);

  const linkSvg = (
    <svg
      className={`link-icon ${isHovered ? "link-hover" : ""}`}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="#EAF0DE"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
      />
    </svg>
  );

  const githubSvg = (
    <svg
      className={`link-icon ${isHovered ? "github-hover" : ""}`}
      width="800px"
      height="800px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 22.0268V19.1568C16.0375 18.68 15.9731 18.2006 15.811 17.7506C15.6489 17.3006 15.3929 16.8902 15.06 16.5468C18.2 16.1968 21.5 15.0068 21.5 9.54679C21.4997 8.15062 20.9627 6.80799 20 5.79679C20.4558 4.5753 20.4236 3.22514 19.91 2.02679C19.91 2.02679 18.73 1.67679 16 3.50679C13.708 2.88561 11.292 2.88561 8.99999 3.50679C6.26999 1.67679 5.08999 2.02679 5.08999 2.02679C4.57636 3.22514 4.54413 4.5753 4.99999 5.79679C4.03011 6.81549 3.49251 8.17026 3.49999 9.57679C3.49999 14.9968 6.79998 16.1868 9.93998 16.5768C9.61098 16.9168 9.35725 17.3222 9.19529 17.7667C9.03334 18.2112 8.96679 18.6849 8.99999 19.1568V22.0268"
        stroke="#EAF0DE"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 20.0267C6 20.9999 3.5 20.0267 2 17.0267"
        stroke="#EAF0DE"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );

  const handleClick = (link) => {
    window.open(link, "_blank");
  };

  useEffect(() => {
    if (isHovered) {
      setIsHovered(true);
    } else {
      setIsHovered(false);
    }
  }, [isHovered]);

  let displayIcon;
  switch (props.icon) {
    case "link":
      displayIcon = linkSvg;
      break;
    default: // Default is github icon
      displayIcon = githubSvg;
  }

  return (
    <div
      className={`project-link ${props.link ? "" : "hidden"}`}
      onClick={() => handleClick(props.link)}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {displayIcon}
    </div>
  );
}

export default SvgTrace;
