import "./styles/App.css";
import Home from "./sections/Home";
import Expertise from "./sections/Expertise";
import Work from "./sections/Work";
import Contact from "./sections/Contact";
import Tab from "./__tests__/Tab";

function App() {
  return (
    <>
      <Tab />
      <Home />
      <Expertise />
      <Work />
      <Contact />
    </>
  );
}

export default App;
