import "../styles/Expertise.css";
import reactImage from "../images/React-icon.svg";
import softwareDevImage from "../images/software-development-icon.svg";
import dataAnalysisImage from "../images/data-analysis-icon.svg";
function Expertise() {
  return (
    <>
      <section className={"expertise"}>
        <h1 className={"section-title"}>Expertise</h1>
        <div className={"skills container"}>
          <div className={"skill"}>
            <div className={"skill-title-section"}>
              <img alt="" src={reactImage} className={"skill-image"} />
              <h2 className={"skill-name"}>Web development</h2>
            </div>
            <div className={"skill-desc-section"}>
              <span className={"skill-desc"}>
                Passionate about UI/UX, having developed websites using React,
                Svelte, and back-end tools such as Firebase
              </span>
            </div>
          </div>

          <div className={"skill"}>
            <div className={"skill-title-section"}>
              <img alt="" src={softwareDevImage} className={"skill-image"} />
              <h2 className={"skill-name"}>Software Design</h2>
            </div>
            <div className={"skill-desc-section"}>
              <span className={"skill-desc"}>
                Dedicated to building robust and scalable software solutions
                with a solid foundation in Java and Python
              </span>
            </div>
          </div>

          <div className={"skill"}>
            <div className={"skill-title-section"}>
              <img alt="" src={dataAnalysisImage} className={"skill-image"} />
              <h2 className={"skill-name"}>Data Analytics</h2>
            </div>
            <div className={"skill-desc-section"}>
              <span className={"skill-desc"}>
                Skilled in leveraging data to derive meaningful insights by
                using Python for data manipulation
              </span>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Expertise;
