// import React, { useState } from "react";
import "../styles/Modal.css";

const Modal = ({
  isOpen,
  onClose,
  title,
  image,
  description,
  tools,
  links,
}) => {
  if (!isOpen) return;

  return (
    <>
      <div className="modal-overlay" onClick={onClose}>
        <div
          className="modal-content"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <button className="close-btn" onClick={onClose}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="white"
              className="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6 18 18 6M6 6l12 12"
              />
            </svg>
          </button>
          <h1 className="modal-project-title">{title}</h1>
          <div className="modal-tools-and-links">
            <div className={"modal-tools"}>{tools}</div>
            <div className={"modal-links"}>{links}</div>
          </div>
          <div className="modal-details">
            <div className="modal-project-image-container">
              <img
                alt="project screenshot"
                src={image || ""}
                className={"modal-project-image"}
              />
            </div>
            <p className="modal-project-description">{description}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
