import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

const CAMERA_POSITION_X = 0;
const CAMERA_POSITION_Y = 0;
const CAMERA_POSITION_Z = 48;

export default class HomeSceneInit {
  constructor(canvasId) {
    // NOTE: Core components to initialize Three.js app.
    this.scene = undefined;
    this.camera = undefined;
    this.renderer = undefined;

    // NOTE: Camera params;
    this.fov = 45;
    this.nearPlane = 1;
    this.farPlane = 1000;
    this.canvasId = canvasId;

    // NOTE: Additional components.
    this.clock = undefined;
    this.stats = undefined;

    // NOTE: Lighting is basically required.
    this.ambientLight = undefined;
    this.directionalLight = undefined;

    // Scene objects
    this.orbitObjects = [];
    this.orbitSpeeds = [];
    this.sceneObjects = [];
    this.orbitDirection = {
      x: 0.0,
      y: 0.5,
      z: 0.0,
    };
  }

  initialize() {
    this.scene = new THREE.Scene();
    this.camera = new THREE.PerspectiveCamera(
      this.fov,
      window.innerWidth / window.innerHeight,
      1,
      1000
    );
    this.camera.position.x = CAMERA_POSITION_X;
    this.camera.position.y = CAMERA_POSITION_Y;
    this.camera.position.z = CAMERA_POSITION_Z;

    // NOTE: Specify a canvas which is already created in the HTML.
    const canvas = document.getElementById(this.canvasId);
    this.renderer = new THREE.WebGLRenderer({
      canvas,
      alpha: true,
      // NOTE: Anti-aliasing smooths out the edges.
      antialias: true,
    });
    this.renderer.setClearColor(0x000000, 0);
    this.renderer.setSize(window.innerWidth, window.innerHeight);
    // this.renderer.shadowMap.enabled = true;
    document.getElementById("home").appendChild(this.renderer.domElement);

    this.clock = new THREE.Clock();

    this.controls = new OrbitControls(this.camera, this.renderer.domElement);

    // ambient light which is for the whole scene
    this.ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
    this.ambientLight.castShadow = true;
    this.scene.add(this.ambientLight);

    // directional light - parallel sun rays
    this.directionalLight = new THREE.DirectionalLight(0xffffff, 1);
    // this.directionalLight.castShadow = true;
    this.directionalLight.position.set(0, 32, 64);
    this.scene.add(this.directionalLight);

    this.initializeObjects();

    // if window resizes
    window.addEventListener("resize", () => this.onWindowResize(), false);
  }

  animate() {
    for (let i = 0; i < this.orbitObjects.length; i++) {
      this.orbitObjects[i].rotation.x +=
        this.orbitDirection.x * this.orbitSpeeds[i];
      this.orbitObjects[i].rotation.y +=
        this.orbitDirection.y * this.orbitSpeeds[i];
    }
    window.requestAnimationFrame(this.animate.bind(this));
    this.render();
  }

  render() {
    // NOTE: Update uniform data on each render.
    // this.uniforms.u_time.value += this.clock.getDelta();
    this.renderer.render(this.scene, this.camera);
  }

  onWindowResize() {
    this.camera.aspect = window.innerWidth / window.innerHeight;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(window.innerWidth, window.innerHeight);
  }

  initializeObjects() {
    // Geometries
    // const geometries = [
    //   new THREE.BoxGeometry(24, 0.5, 0.5),
    //   new THREE.BoxGeometry(24, 1, 1),
    //   new THREE.BoxGeometry(24, 2, 2),
    //   new THREE.BoxGeometry(32, 0.5, 0.5),
    //   new THREE.BoxGeometry(32, 1, 1),
    //   new THREE.BoxGeometry(32, 2, 2),
    // ];

    // Materials
    const materials = [
      new THREE.MeshStandardMaterial({
        color: 0x4287f5,
        roughness: 0.9,
        metalness: 0.2,
      }),
      new THREE.MeshStandardMaterial({
        color: 0xed0726,
        roughness: 0.9,
        metalness: 0.2,
      }),
      new THREE.MeshStandardMaterial({
        color: 0xe650c8,
        roughness: 0.9,
        metalness: 0.2,
      }),
    ];

    // Objects
    const numObjects = 40;

    for (let i = 0; i < numObjects; i++) {
      const width = getRandomNumber(0.3, 2.5);
      const geometry = new THREE.BoxGeometry(
        getRandomNumber(16, 36),
        width,
        width
      );
      this.createShape({
        geometry: geometry,
        material: getRandomItem(materials),
        position: { y: getRandomNumber(-15, 15), z: getRandomNumber(-8, 10) },
        initialRotation: getRandomNumber(0, 2 * Math.PI),
        orbitSpeed: getRandomNumber(0.001, 0.005),
      });
    }
  }

  createShape(param = {}) {
    const object = new THREE.Mesh(param.geometry, param.material);
    object.position.set(0, param.position.y, param.position.z);

    this.sceneObjects.push(object);

    const pivot = new THREE.Object3D();

    pivot.position.set(CAMERA_POSITION_X, CAMERA_POSITION_Y, CAMERA_POSITION_Z);
    pivot.attach(object);
    pivot.rotation.y = param.initialRotation;

    this.orbitSpeeds.push(param.orbitSpeed);
    this.orbitObjects.push(pivot);
    this.scene.add(pivot);
  }
}

function getRandomItem(list) {
  const randomIndex = Math.floor(Math.random() * list.length);
  return list[randomIndex];
}

function getRandomNumber(min, max) {
  return Math.random() * (max - min) + min;
}
