import "../styles/Work.css";
import Project from "./Project";
import chefMateImage from "../images/chefmate-image.png";
import omnifoodImage from "../images/omnifood-image.png";
import chatBoxGptImage from "../images/chat-box-gpt.png";
import sudokuImage from "../images/sudoku-project.png";
import what2watchImage from "../images/What2Watch - Placeholder.png";
import tetrisImage from "../images/tetris-demo.png";

function Work() {
  return (
    <>
      <section className={"work"}>
        <h1 className={"section-title"}>My Work</h1>
        <div className={"projects container"}>
          <Project
            image={what2watchImage}
            title={"What 2 Watch - Anime scheduler"}
            github={"https://github.com/Xoann/what2watch"}
            link={"https://animeradar.netlify.app"}
            tools={["svelte", "javascript"]}
            size={"medium"}
            description={
              "AniRadar is a sleek, user-friendly application built with Svelte that leverages the AniList API. It enables users to track their progress while watching seasonal anime and receive alerts when new episodes are released."
            }
          />
          <Project
            image={tetrisImage}
            title={"Tetris"}
            tools={["mips"]}
            size={"medium"}
            description={
              "Tetris in MIPS Assembly is a faithful recreation of the classic game, featuring all the original elements, including line clearing, scoring, and increasing difficulty."
            }
          />
          <Project
            image={sudokuImage}
            title={"Sudoku Solver"}
            github={"https://github.com/Xoann/Sudoku"}
            tools={["python", "javascript", "html", "css"]}
            size={"medium"}
            description={
              "Sudoku Solver is a powerful application with a Python backend that solves user-created Sudoku boards in real-time."
            }
          />
          <Project
            image={chefMateImage}
            title={"Chef Mate - Recipe keeping website"}
            github={"https://github.com/Xoann/TheRecipeBook"}
            link={"https://chefmate.ca"}
            tools={["firebase", "javascript", "html", "css"]}
            size={"medium"}
            description={
              "Chef Mate is a dynamic web application built with HTML, CSS, JavaScript, and Firebase. It allows you to create your own virtual recipe book and share your favorite recipes with friends."
            }
          />
          <Project
            image={chatBoxGptImage}
            title={"GPT Chat Room"}
            github={"https://github.com/Xoann/207-Project"}
            tools={["java"]}
            size={"medium"}
            description={
              "GPT Chat Room is an innovative project built in JavaScript using the OpenAI API. It enables users to create chat rooms where GPT provides intelligent response recommendations."
            }
          />
          <Project
            image={omnifoodImage}
            title={"Omnifood"}
            link={"https://omnifood-xoan.netlify.app"}
            tools={["javascript", "html", "css"]}
            size={"medium"}
            description={
              "Omnifood is a website for a fictional company crafted with HTML, CSS, and JavaScript. This project showcases a modern, responsive design and a seamless user experience, highlighting skills in front-end development and web design."
            }
          />
        </div>
      </section>
    </>
  );
}

export default Work;
