import firebaseIcon from "../images/firebase-icon.svg";
import javascriptIcon from "../images/js-icon.svg";
import pythonIcon from "../images/python.svg";
import javaIcon from "../images/java-icon.svg";
import reactIcon from "../images/React-icon.svg";
import htmlIcon from "../images/html-icon.svg";
import cssIcon from "../images/css-icon.svg";
import threejsIcon from "../images/threejs-icon.svg";
import svelteIcon from "../images/svelte.svg";
import SvgTrace from "../SvgTrace";
import Modal from "../components/Modal";
import React, { useState } from "react";
import mipsIcon from "../images/mips-icon.svg";

function Project(props) {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const toolIcons = {
    firebase: firebaseIcon,
    javascript: javascriptIcon,
    python: pythonIcon,
    java: javaIcon,
    react: reactIcon,
    html: htmlIcon,
    css: cssIcon,
    three: threejsIcon,
    svelte: svelteIcon,
    mips: mipsIcon,
  };

  const tools = [];
  for (const tool of props.tools || []) {
    tools.push(
      <div className={"tool"}>
        <img src={toolIcons[tool]} alt="" />
        <div className={"tool-label"}>{tool}</div>
      </div>
    );
  }

  const links = (
    <>
      <SvgTrace link={props.github} icon={"github"} text={"Code"} />
      <SvgTrace link={props.link} icon={"link"} text={"Project"} />
    </>
  );

  return (
    <>
      <div className={`project ${props.size}`}>
        <Modal
          isOpen={isOpen}
          onClose={closeModal}
          title={props.title}
          image={props.image}
          description={props.description}
          tools={tools}
          links={links}
        />
        <div className={"project-image-container"} onClick={openModal}>
          <img alt="" src={props.image || ""} className={"project-image"} />
        </div>
        <div className={"project-title-section"}>
          <div>
            <h3 className={"project-title"} onClick={openModal}>
              {props.title}
            </h3>
          </div>
          <div className={"tools-and-links"}>
            <div className={"tools"}>{tools}</div>

            <div className={"links-container"}>{links}</div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Project;
