import "../styles/Home.css";
import NavHover from "../components/NavHover";
import { useEffect } from "react";
// import * as THREE from "three";
import HomeSceneInit from "../HomeSceneInit";

function Home() {
  useEffect(() => {
    const shapes = new HomeSceneInit("canvas");
    shapes.initialize();
    shapes.animate();
  }, []);

  return (
    <section className={"home"} id={"home"}>
      <canvas id={"canvas"}></canvas>
      <NavHover />
      <div className={"title-section"}>
        <h1 className={"name"}>Liam Maguire</h1>
        <h3 className={"short-desc"}>Computer Science Student</h3>
      </div>
      <div className={"see-more"}>click</div>
    </section>
  );
}

export default Home;
