import { useEffect, useState, useCallback } from "react";
import "../styles/NavHover.css";

function NavHover({ children }) {
  const [hoveredElement, setHoveredElement] = useState(null);
  const [scrolledPastPoint, setScrolledPastPoint] = useState(false);

  const handleMouseEnter = (index) => {
    setHoveredElement(index);
  };

  const handleMouseLeave = () => {
    setHoveredElement(null);
  };

  // const stickyNavScrollPoint = window.innerHeight - 1;
  // const handleScroll = () => {
  //   if (window.scrollY > stickyNavScrollPoint && !scrolledPastPoint) {
  //     setScrolledPastPoint(true);
  //   } else if (window.scrollY <= stickyNavScrollPoint && scrolledPastPoint) {
  //     setScrolledPastPoint(false);
  //   }
  // };

  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll);
  // }, [scrolledPastPoint]);

  const stickyNavScrollPoint = window.innerHeight - 1;
  const handleScroll = useCallback(() => {
    if (window.scrollY > stickyNavScrollPoint && !scrolledPastPoint) {
      setScrolledPastPoint(true);
    } else if (window.scrollY <= stickyNavScrollPoint && scrolledPastPoint) {
      setScrolledPastPoint(false);
    }
  }, [scrolledPastPoint, stickyNavScrollPoint]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  const ITEMS = ["home", "expertise", "work", "contact"];

  function scrollToDiv(item) {
    const target = document.querySelector(`.${item}`);

    if (target) {
      target.scrollIntoView({ behavior: "smooth" });
    }
  }

  return (
    // TODO make nav sticky when scrolling past home section
    <div className={`nav ${scrolledPastPoint ? "sticky-nav" : ""}`}>
      {ITEMS.map((item, index) => (
        <div
          onClick={() => scrollToDiv(item)}
          key={index}
          className={`nav-item ${
            hoveredElement !== index && hoveredElement != null
              ? "not-hovered"
              : ""
          }`}
          onMouseEnter={() => handleMouseEnter(index)}
          onMouseLeave={handleMouseLeave}
        >
          <div className={"nav-item-index"}>{`0${index}`}</div>
          <div className={"nav-item-name"}>{`// ${item}`}</div>
        </div>
      ))}
    </div>
  );
}

export default NavHover;
